

*{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
body{
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    background-image: url('../assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 130px; /* Set this to the height of your navbar */
}
h2{
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    padding: 30px 0;
}

/* Table Styles */

.App11{
    padding-left: 10%;  /* Innenabstand links */
    padding-right: 10%; /* Innenabstand rechts */
}

.table-wrapper{
    margin: 10px 70px 70px;
    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.fl-table {
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    
}

.fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 14px;
}

.fl-table thead th {
    color: #ffffff;
    background: #375572;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}
.fl-table tbody td {
    border-bottom: 1px solid #ffffff;
}
.fl-table th:nth-child(1),
.fl-table td:nth-child(1),
.fl-table th:nth-child(7),
.fl-table td:nth-child(7) {
  width: 70px; /* Ändere die Breite nach Bedarf */
}
/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }
    .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .fl-table thead th:last-child{
        border-bottom: none;
    }
    .fl-table thead {
        float: left;
    }
    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .fl-table tbody tr {
        display: table-cell;
    }
    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}



progress {
    border-radius: 7px; 
    width: 80%;
    height: 22px;
    margin-left: -11.5%;
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }

  progress::-webkit-progress-value {
    background-image:
    -webkit-linear-gradient(-45deg, 
                            transparent 33%, rgba(0, 0, 0, .1) 33%, 
                            rgba(0,0, 0, .1) 66%, transparent 66%),
    -webkit-linear-gradient(top, 
                            rgba(255, 255, 255, .25), 
                            rgba(0, 0, 0, .25)),
    -webkit-linear-gradient(left, #09c, rgb(38, 81, 219));

 border-radius: 2px; 
 background-size: 35px 20px, 100% 100%, 100% 100%;
  }


