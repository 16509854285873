@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
/************ Default Css ************/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
  }
  
  body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
  }
  
  p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  button {
    border: 0;
    background-color: transparent;
  }
  
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
  
  @media (min-width:1700px) {
      main .container {
          max-width: 100%;
          padding: 0 150px;
      }
  }
  
  p.success {
      color: green;
  }
  
  p.danger {
      color: red;
  }


  /************ Button ************/

  .submit {
    display: flex;
    padding: 9px 26px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: white;
    background: #6225E6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    border: none;
  }
  
  .submit:focus {
    outline: none;
  }
  
  .submit:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #FBC638;
  }
  
  .submit .second {
    transition: 0.5s;
    margin-right: 0px;
  }
  
  .submit:hover  .second {
    transition: 0.5s;
    margin-right: 45px;
  }
  
  .span {
    transform: skewX(15deg)
  }
  
  .second {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }
  
  .one {
    transition: 0.4s;
    transform: translateX(-60%);
  }
  
  .two {
    transition: 0.5s;
    transform: translateX(-30%);
  }
  
  .submit:hover .three {
    animation: color_anim 1s infinite 0.2s;
  }
  
  .submit:hover .one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }
  
  .submit:hover .two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }
  
  @keyframes color_anim {
    0% {
      fill: white;
    }
  
    50% {
      fill: #FBC638;
    }
  
    100% {
      fill: white;
    }
  }


/* From https://css.glass */
.App3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 0;
    padding: 260px 0 100px 0;
    background-image: url('../../assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

.App2{

    width: auto ;
    height: auto ;
    padding: 50px 50px;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.62);
}

.App2 form input, .contact form textarea {

    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  
  }
  .App2 form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  .App2 form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  .App2 form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }




  label {
    display: block;
    width: auto;
    height: auto;
    cursor: pointer;
  }
  
  input[type='checkbox'] {
    position: absolute;
    transform: scale(0);
  }
  
  input[type='checkbox']:checked ~ .checkbox::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 8px;
    transform: rotate(45deg);
    width: 8px;
    height: 4px;
    border-color: #24c78e;
    border-width: 2px;
    border-top: 0;
    border-left: 0;
    border-right: solid 2px;
    border-bottom: solid 2px;
  }
  
  .checkbox {
    display: block;
    width: 24px;
    height: 24px;
    fill: none;
    stroke: #2a2a2ab7;
    stroke-width: 3px;
    transition: all 0.375s;
  }
  
  .checkbox-border {
    stroke-width: 3px;
    stroke: #2a2a2ab7;
    fill: none;
  }
  
  .checkbox-checkmark {
    stroke-width: 0;
    stroke: none;
    fill: none;
  }
  
  .checked .checkbox-border {
    stroke-width: 0;
    stroke: none;
    fill: none;
  }
  
  .checked .checkbox-checkmark {
    stroke-width: 3;
    stroke: #24c78e;
    fill: none;
  }
  